import localeSv from 'dayjs/locale/sv'
import localeDa from 'dayjs/locale/da'
import localeEn from 'dayjs/locale/en-gb'
import localeNb from 'dayjs/locale/nb'
import localePl from 'dayjs/locale/pl'
import localeZhCn from 'dayjs/locale/zh-cn'

export const LANGS_DATE_FORMATS = {
  sv: localeSv,
  da: localeDa,
  en: localeEn,
  nb: localeNb,
  pl: localePl,
  'zh-cn': localeZhCn,
  zz: localeSv,
}

/**
 * Dayjs date formats
 * @see https://day.js.org/docs/en/display/format
 */
export const LANGS_DATE_CUSTOM_FORMATS = {
  short: {
    nb: 'DDMMYYYY',
    da: 'DDMMYYYY',
    sv: 'YYYYMMDD',
  },
  yearFirstFull: {
    nb: 'YYYYMMDD',
    da: 'YYYYMMDD',
    sv: 'YYYYMMDD',
  },
  fullTime: 'HH:mm',
  fullMonthYear: 'MMMM YYYY',
  fullDate: 'dddd D MMMM YYYY',
  dateShortMonth: 'D MMM',
  dateLongMonth: 'D MMMM',
  weekdayShort: 'ddd',
  weekday: 'dddd',
  dateWithShortWeekday: Object.fromEntries(
    Object.entries(LANGS_DATE_FORMATS)
      .map(
        entryLocale => [entryLocale[0], entryLocale[1].formats.L + ' ddd']
      )
  ),
  longWithoutTime: 'ddd, MMM D, YYYY',
  monthDate: 'D MMMM',
  customDateMonth: 'MMM',
  customDateDay: 'D',
}

export const DATEPICKER_DATE_FORMAT = 'M/D/YYYY'

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
